@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  @apply bg-slate-900;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fadein {
  animation: fadeIn ease 10s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.buttonShadow {
  @apply rounded font-bold py-1 px-2;
}

.socialButton {
  padding: 0;
  width: 3em;
  height: 3em;
  border-radius: 100%;
  background-color: rgb(25, 28, 28);
  transition: all 0.3s ease 0s;
}

.socialButton:hover {
  background-color: var(--color);
  -webkit-box-shadow: 0 10px 15px -3px rgba(110, 84, 148, 0.4);
  box-shadow: 0 10px 15px -3px var(--color);
  transform: translateY(-7px);
}

.hidden {
  display: none;
}

.text-box {
  @apply bg-gray-800 p-7 shadow-lg shadow-gray-800/50 whitespace-normal;
}

.text-box:hover .hidden {
  display: block;
}
